body {
  margin: 0;
  background-color: #f6f6f6;
  color: #333;
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none !important;
  outline: none;
  box-sizing: border-box;
}

.new-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 50%);
  z-index: 1111;
  opacity: 0;
  visibility: hidden;
}

/* For data grid styling */
.InovuaReactDataGrid__column-header--align-start.InovuaReactDataGrid__column-header--direction-ltr>.InovuaReactDataGrid__column-header__menu-tool--show-on-hover {
  display: none;
}

.InovuaReactDataGrid .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid--columns-ready .InovuaReactDataGrid--zebra-rows .InovuaReactDataGrid--show-hover-rows .InovuaReactDataGrid--direction-ltr .InovuaReactDataGrid--no-header-border-right .InovuaReactDataGrid--cell-borders-horizontal .InovuaReactDataGrid--cell-borders-vertical {
  width: 1900px;
  overflow-x: scroll;
}

.InovuaReactDataGrid--theme-default-light {
  min-height: 58vh !important;
}

.InovuaReactDataGrid--theme-default-light {
  width: 100% !important;
}

.main-column {
  width: 100%;
  position: relative;
  padding: 0 0 0 32px;
}

.main-column .column-selector>div {
  padding: 15px;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.main-column .column-selector>div input {
  width: 18px;
  height: 18px;
}

.main-column .column-selector {
  position: absolute;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 40px;
  left: 0;
  z-index: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 15%);
  background-color: #fff;
  width: 100%;
}

.main-column button {
  width: 201px;
  background-color: #fff;
  border: 2px solid #1a1c35;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  color: #1a1c35;
  padding: 0 10px;
  height: 40px;
  position: relative;
  margin-right: auto;
}

/* For WebKit browsers (Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

button {
  cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* action popup */
.action-main {
  width: 155px;
  border-radius: 10px;
  box-shadow: 0px 4px 18px rgb(0 0 0 / 9%);
  background-color: #fff;
  position: absolute;
  top: 60px;
  right: 45px;
  z-index: 1;
  opacity: 1;
  /* visibility: hidden; */
}

.action-main.block-active {
  z-index: 0;
}

.action-main.delete-active {
  z-index: 0;
}

.action-main.action-active {
  opacity: 1;
  visibility: visible;
}

.action-main ul {
  width: 100%;
  margin: 0;
}

.action-main ul li {
  border-bottom: 1px solid #f2f2f2;
}

.action-main ul li:last-child {
  border: none;
}

.action-main ul li a {
  font-size: 14px;
  color: #4f4f4f;
  width: 100%;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 15px;
}

.action-main ul li a span {
  width: 25px;
  display: flex;
}

.action-main ul li a.block-btn {
  color: #4f4f4f;
}

.action-main ul li a img {
  margin: 0 5px 0 0;
  width: 13px;
}

.action-main ul li a img {
  margin: 0 11px 0 0;
}

/* add employee popup */
.add-employe-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 50%);
  z-index: 1111;
  /* visibility: hidden;
    opacity: 0; */
}

.add-employee-main,
.edit-employee-main {
  width: 858px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 44px 24px 24px;
  z-index: 1111;
  border-radius: 10px;
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.add-employee-main.add-member-active {
  opacity: 1;
  visibility: visible;
}

.add-employee-main button.close-btn,
.edit-employee-main button.close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

.add-employee-main .head,
.edit-employee-main .head {
  /* border-bottom:1px solid #E5E7EB; */
  /* padding: 0 0 9px; */
  margin: 0 0 60px;
  text-align: center;
}

.add-employee-main .head h3,
.edit-employee-main .head h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.add-employee-main .add-employee-details,
.edit-employee-main .add-employee-details {
  width: 100%;
  display: flex;
  gap: 24px;
}

.add-employee-main .add-employee-details .profile,
.edit-employee-main .add-employee-details .profile {
  width: 197px;
  text-align: center;
}

.add-employee-main .add-employee-details .profile label,
.edit-employee-main .add-employee-details .profile label {
  color: #6b7280;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.add-employee-main .add-employee-details .profile .profile-img,
.edit-employee-main .add-employee-details .profile .profile-img {
  width: 100%;
  position: relative;
}

.add-employee-main .add-employee-details .profile .profile-img>img,
.edit-employee-main .add-employee-details .profile .profile-img>img {
  width: 100%;
  height: 197px;
  object-fit: cover;
  border-radius: 10px;
}

.add-employee-main .add-employee-details .profile .profile-img .delete-btn,
.edit-employee-main .add-employee-details .profile .profile-img .delete-btn {
  background-color: #ff3c3c;
  width: 42px;
  height: 42px;
  border: none;
  border-radius: 10px;
  position: absolute;
  bottom: 2px;
  right: 0;
}

.add-employee-main .add-employee-details .profile .profile-img .delete-btn input,
.edit-employee-main .add-employee-details .profile .profile-img .delete-btn input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group,
.edit-employee-main .add-employee-details .employee-info-fill .input-group {
  width: 100%;
  display: flex;
  gap: 29px;
  margin: 0 0 17px;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field,
.edit-employee-main .add-employee-details .employee-info-fill .input-group .input-field {
  width: 50%;
}

.employee-info-main .add-employee-details .employee-info-fill .input-group .input-field.w-100,
.edit-employee-main .add-employee-details .employee-info-fill .input-group .input-field.w-100 {
  width: 100%;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field textarea,
.edit-employee-main .add-employee-details .employee-info-fill .input-group .input-field textarea {
  width: 100%;
  height: 91px;
  border-radius: 10px;
  background-color: #f1f1f1;
  padding: 17px 20px;
  border: none;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field label,
.edit-employee-main .add-employee-details .employee-info-fill .input-group .input-field label {
  display: block;
  color: #9ca3af;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 4px;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field input,
.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field select,
.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field button,
.edit-employee-main .add-employee-details .employee-info-fill .input-group .input-field input {
  width: 100%;
  height: 54px;
  border-radius: 10px;
  padding: 0 10px;
  border: none;
  color: #4b5563;
  border: 1px solid #d0d0d0;
  background-color: #fff;
}

.input-field-data select{
    width: 100%;
      height: 45px;
      border-radius: 10px;
      padding: 0 10px;
      border: none;
      color: #4b5563;
      border: 1px solid #d0d0d0;
      background-color: #fff;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .calc-select,
.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .sheet-select {
  width: 100%;
  display: flex;
  gap: 11px;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .sheet-select button {
  width: 25%;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .calc-select button,
.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .sheet-select button {
  width: 50%;
  height: 54px;
  border: 1px solid transparent;
  background-color: #f6f6f6;
  color: #4b5563;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
}

.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .calc-select button.active,
.add-employee-main .add-employee-details .employee-info-fill .input-group .input-field .sheet-select button.active {
  border: 1px solid #fe0000;
  color: #fe0000;
  background-color: #fff3f3;
}

.employee-info-main .add-employee-details .employee-info-fill .input-group .input-field input,
.edit-employee-main .add-employee-details .employee-info-fill .input-group .input-field input {
  background-color: #f1f1f1;
  border: none;
}

.add-employee-main .add-employee-details .employee-info-fill .employee-permission label,
.edit-employee-main .add-employee-details .employee-info-fill .employee-permission label {
  display: block;
  margin: 0 0 12px;
  color: #9ca3af;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}

.add-employee-main .add-employee-details .employee-info-fill .employee-permission ul,
.edit-employee-main .add-employee-details .employee-info-fill .employee-permission ul {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.add-employee-main .add-employee-details .employee-info-fill .employee-permission ul li,
.edit-employee-main .add-employee-details .employee-info-fill .employee-permission ul li {
  padding: 7px 10px;
  border: 1px solid #f1f1f1;
  font-size: 12px;
  font-weight: 500;
  color: #7f7f7f;
  cursor: pointer;
  border-radius: 10px;
}

.add-employee-main .add-employee-details .employee-info-fill .employee-permission ul li.active,
.edit-employee-main .add-employee-details .employee-info-fill .employee-permission ul li.active {
  border: 1px solid #ff3c3c;
  background-color: #ffe0e0;
  display: flex;
  gap: 10px;
  color: #ff3c3c;
}

.add-employee-main .add-employee-details .employee-info-fill,
.edit-employee-main .add-employee-details .employee-info-fill {
  width: calc(100% - 197px);
}

.add-employee-main .add-employee-btn,
.edit-employee-main .add-employee-btn {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 0 0 46px;
  padding: 19px 0 0;
  margin: 15px 0 0;
}

.employee-info-main .add-employee-btn,
.edit-employee-main .add-employee-btn {
  justify-content: center;
  gap: 15px;
  margin: 23px 0 0;
}

.edit-employee-main .add-employee-btn button {
  background-color: #fff3f3 !important;
  border: none !important;
}

.add-employee-main .add-employee-btn button.cancle-btn {
  background-color: #fff3f3;
  color: #fe0000;
}

.add-employee-main .add-employee-btn button,
.edit-employee-main .add-employee-btn button {
  width: 166px;
  height: 48px;
  background-color: #ff3c3c;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
}

.employee-info-main .add-employee-btn button,
.edit-employee-main .add-employee-btn button {
  background-color: #ffe0e0;
  border: 1px solid #ff3c3c;
  color: #ff3c3c;
}

.edit-employee-main .add-employee-btn button.save-btn {
  background-color: #fe0000 !important;
  color: #fff;
}

input,
textarea {
  font-family: "Poppins", sans-serif;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 70%);
  opacity: 2;
  /* visibility: hidden; */
  z-index: 11;
}

.overlay.add-member-active {
  opacity: 1;
  visibility: visible;
}

.overlay.change-active {
  opacity: 1;
  visibility: visible;
}

.overlay.logout-main-active {
  opacity: 1;
  visibility: visible;
}

/* logout confirm popup */
.block-main,
.logout-main,
.delete-guest-main,
.guard-vehicle-main,
.delete-main {
  width: 100%;
  max-width: 340px;
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 30px 38px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 12;
  z-index: 1111;
}

.delete-guest-main.delete-active {
  opacity: 1;
  visibility: visible;
}

.block-main.block-active {
  opacity: 1;
  visibility: visible;
}

.block-main.customer-block-active {
  opacity: 1;
  visibility: visible;
}

.logout-main.logout-main-active {
  opacity: 1;
  visibility: visible;
}

.block-main.delete-coupon-active {
  opacity: 1;
  visibility: visible;
}

.block-main .delete-icon,
.logout-main .delete-icon,
.delete-guest-main .delete-icon,
.guard-vehicle-main .delete-icon,
.delete-main .delete-icon {
  width: auto;
  background: none;
  border: none;
}

.block-main .delete-icon img {
  width: 35px;
  height: 35px;
}

.delete-guest-main .delete-icon img {
  width: 27px;
}

.block-main h2,
.logout-main h2,
.delete-guest-main h2,
.guard-vehicle-main h2,
.delete-main h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin: 12px 0 0;
}

.block-main p,
.logout-main p,
.delete-guest-main p,
.guard-vehicle-main p,
.delete-main p {
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  margin: 12px 0;
}

.logout-main p {
  margin: 12px 0 30px;
}

.block-main .block-btn,
.logout .block-btn,
.delete-guest-main .block-btn,
.guard-vehicle-main .block-btn,
.delete-main .block-btn {
  width: 100%;
}

.block-main .vendor-block {
  margin: 18px 0 0;
  display: inline-block;
}

.block-main .block-btn button,
.logout-main .block-btn button,
.delete-guest-main button,
.guard-vehicle-main .block-btn button,
.delete-main .block-btn button {
  width: 80px;
  height: 36px;
  color: #413740;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  margin: 0 10px 0 0;
  border: none;
  background: none;
}

.delete-main .block-btn button {
  color: #ff3c3c;
}

.block-main .block-btn button.logout-btn,
.logout-main .block-btn button.logout-btn,
.delete-guest-main .block-btn button.logout-btn,
.guard-vehicle-main .block-btn button.logout-btn,
.delete-main .block-btn button.logout-btn {
  background-color: #ff3c3c;
  color: #fff;
}

/* change password popup */
.change-password-main {
  width: 378px;
  border-radius: 10px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 10;
  z-index: 1111;
  padding: 29px 30px 19px 30px;
  transform: translate(-50%, -50%);
}

.change-password-main.change-active {
  opacity: 1;
  visibility: visible;
}

.change-password-main .change-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 43px;
}

.change-password-main .change-head h2 {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

.change-password-main .change-head button {
  background: none;
  border: none;
}

.change-password-main .input-field {
  width: 100%;
  margin: 0 0 30px;
}

.change-password-main .input-field label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  margin: 0 0 8px;
}

.change-password-main .input-field .password {
  width: 100%;
  position: relative;
}

.change-password-main .input-field .password input {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 0 19px;
}

.change-password-main .input-field .password span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 17px;
}

.change-password-main .password-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.change-password-main .password-btn button {
  width: 88px;
  height: 48px;
  border: none;
  background: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  color: #ff3c3c;
  letter-spacing: 1px;
  font-weight: bold;
}

.change-password-main .password-btn button.Save-btn {
  background-color: #ff3c3c;
  color: #fff;
}

/* Login pages css start */
#Dashboard-login-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.dashboard-input-left {
  width: 50%;
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
}

.dashboard-input-left .logo {
  width: 100%;
  margin: 0 0 10px 0;
}

.dashboard-input-left .logo a {
  display: inline-block;
  text-align: left;
  width: max-content;
}

.dashboard-input-left .logo a img {
  width: 100px;
  height: auto;
}

.dashboard-input-left .login-input-details {
  width: 100%;
}

.dashboard-input-left .login-input-details h2 {
  color: #4f4f4f;
  font-size: 32px;
  font-weight: bold;
}

.dashboard-input-left .login-input-details p {
  font-size: 16px;
  color: #4f4f4f;
  margin: 8px 0 34px 0;
}

.dashboard-input-left .login-input-details .input-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
  margin: 0 0 16px 0;
}

.dashboard-input-left .login-input-details .input-group.mb {
  margin-bottom: 12px;
}

.dashboard-input-left .login-input-details .input-group span.icon {
  position: absolute;
  top: 50%;
  left: 21px;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}

.dashboard-input-left .login-input-details .input-group span.eye-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  cursor: pointer;
}

.dashboard-input-left .login-input-details .input-group input {
  width: 100%;
  height: 70px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 0 63px;
  font-size: 16px;
}

.dashboard-input-left .login-input-details .input-group input::placeholder {
  font-size: 16px;
  color: #bdbdbd;
}

.dashboard-input-left .login-input-details .input-group input[type="checkbox"] {
  width: 26px;
  height: 26px;
  display: inline-block;
  accent-color: #1980da;
}

.dashboard-input-left .login-input-details .input-group label {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  margin: 0 0 0 11px;
}

.dashboard-input-left .login-input-details .input-group a {
  color: #ff3c3c;
  font-size: 16px;
  margin: 0 0 16px;
}

.dashboard-input-left .login-input-details .login-btn {
  width: 100%;
  margin: 0 0 26px;
}

.dashboard-input-left .login-input-details .forgot-btn {
  margin: 30px 0 0;
}

.dashboard-input-left .login-input-details .save-btn {
  margin: 43px 0 0;
}

.dashboard-input-left .login-input-details .login-btn button {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  border: none;
  background-color: #ff3c3c;
}

.login-img-right {
  width: 50%;
}

.login-img-right img {
  width: 100%;
  vertical-align: top;
  height: 100vh;
  object-fit: cover;
}

.dashboard-input-left .login-input-details .otp-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 24px 0;
}

.dashboard-input-left .login-input-details .otp-input input {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  outline: none;
}

.dashboard-input-left .login-input-details .resend-otp p {
  color: #4f4f4f;
  font-size: 16px;
}

.dashboard-input-left .login-input-details .resend-otp p a {
  font-size: 16px;
  margin: 0 0 0 6px;
  color: #ff3c3c;
}

.dashboard-input-left .login-input-details .resend-otp {
  width: 100%;
  text-align: left;
}

.dashboard-input-left .login-back-btn {
  width: auto;
  position: absolute;
  top: 56px;
}

.dashboard-input-left .login-back-btn a {
  width: 40px;
  height: 40px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* dashboard css start */
.admin-dashboard {
  width: 100%;
  display: flex;
  background-color: #e6fbf3;
  position: relative;
}

.admin-dashboard.change-active {
  position: fixed;
  height: 100%;
}

.admin-dashboard.block-active {
  position: fixed;
  height: 100%;
}

.admin-dashboard.product-active {
  position: fixed;
  height: 100%;
}

.dashboard-menu {
  width: 269px;
  background-color: #fff;
  padding: 0 27px;
  transition: all 0.5s;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 2px 0 1px rgba(0, 0, 0, 2%);
  z-index: 99;
}

.dashboard-menu.dashboard-active {
  width: 115px;
  transition: all 0.5s;
}

.dashboard-menu.dashboard-active .logo {
  justify-content: center;
  margin: 38px 0 0;
}

.dashboard-menu.dashboard-active .logo .logo-img {
  display: none;
}

.dashboard-menu.dashboard-active .dashboard-options ul li a {
  width: 60px;
  font-size: 0;
  transition: all 0.5s;
  padding: 0 0 0 18px;
}

.dashboard-menu .logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0;
}

.dashboard-menu .logo .logo-img {
  width: auto;
}

.dashboard-menu .logo .logo-img a {
  display: inline-block;
  padding: 0 0;
}

.dashboard-menu .logo .logo-img a img {
  width: 100px;
}

.dashboard-menu .logo .tab-menu-bar a {
  display: inline-block;
}

.dashboard-menu .logo .tab-menu-bar a .fa-times {
  display: none;
}

.dashboard-menu .logo .tab-menu-bar a i {
  font-size: 22px;
}

.dashboard-menu .dashboard-options {
  width: 100%;
  margin: 45px 0 0;
}

.dashboard-menu .dashboard-options ul li a {
  color: #000;
  width: 214px;
  font-weight: 600;
  min-height: 56px;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  line-height: 56px;
  padding: 0 0 0 22px;
  margin: 0 0 14px;
  font-size: 14px;
  border-radius: 10px;
}

.dashboard-menu .dashboard-options ul li a span.menu-icon {
  margin: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-menu .dashboard-options ul li a.active {
  background-color: #000000;
  color: #fff !important;
}

.dashboard-menu .dashboard-options ul li a.active span {
  color: #fff;
}

.dashboard-menu .dashboard-options ul li a.active img {
  filter: brightness(0) invert(1);
}

.dashboard-menu .dashboard-options ul li a span {
  margin: 0 0 0 12px;
  display: inline-block;
}

.main-dashboard {
  width: calc(100% - 269px);
  padding: 0 50px;
  height: 100%;
  /* margin: 0 0 30px; */
  transition: all 0.5s;
}

.main-dashboard.dashboard-active {
  width: calc(100% - 115px);
}

.loaction-list-main {
  width: 100%;
  background-color: #fff;
  display: inline-block;
  border-radius: 10px;
  /* border: 1px solid #e0e0e0; */
  overflow: hidden;
}

.loaction-list-main .loaction-list-head .export-btn {
  width: 117px;
  height: 32px;
  background-color: #fe0000;
  border-radius: 9px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.web-sales-main .loaction-list-head {
  padding: 0 26px 0 21px;
}

.loaction-list-main .loaction-list-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #ededed;
  padding: 0 26px 0 32px;
}

.loaction-list-main .loaction-list-head button.add-user {
  width: 117px;
  height: 32px;
  background-color: #fe0000;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.loaction-list-main .loaction-list-head .main-column {
  width: auto;
  padding: 0;
}

.loaction-list-main .loaction-list-head .main-column button {
  height: 33px;
}

.loaction-list-main .loaction-list-head .search-bar {
  width: 100%;
  max-width: 300px;
  padding: 15px 0;
  position: relative;
}

.loaction-list-main .loaction-list-head .search-bar input {
  width: 100%;
  height: 40px;
  border: 1px solid #e0e0e0;
  background-color: #fdfdfd;
  border-radius: 10px;
  padding: 0 0 0 38px;
}

.loaction-list-main .loaction-list-head .search-bar input::placeholder {
  color: #bdbdbd;
  font-weight: 500;
  font-size: 12px;
}

.loaction-list-main .loaction-list-head .search-bar img {
  position: absolute;
  top: 27px;
  left: 15px;
  color: #bdbdbd;
}

.loaction-list-main .loaction-list-head .add-service {
  background-color: #ff3c3c;
  border: none;
  width: 143px;
  height: 36px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.loaction-list-main .loaction-list-head .add-service img {
  margin: 0 7px 0 0;
}

/* table data start */
table {
  width: 100%;
  border-collapse: collapse;
}

td {
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  color: #333;
}

.booking-msg {
  width: 48px;
  height: 48px;
  background-color: rgb(213 141 232 / 20%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0 0 25px;
}

.booking-msg .msg-number {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #d58de8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  top: -5px;
  right: -5px;
  font-size: 8px;
}

.status {
  padding: 7px 22px;
  border-radius: 10px;
  background-color: #edff88;
  border: none;
  color: #413740;
  font-weight: 500;
  font-size: 12px;
}

.product-img {
  width: 50px;
  height: 50px;
}

.product-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

td.table-col {
  padding: 15px 25px;
}

.table-col {
  position: relative;
}

span.rating-icon {
  display: flex;
  gap: 6px;
}

td.for-order {
  padding: 0 15px;
}

td p {
  color: #828282;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0;
}

td p img {
  margin: 0 5px 0 0;
}

td i {
  margin: 0 0 0 25px;
  color: #bdbdbd;
  cursor: pointer;
}

th.heading {
  color: #828282;
  padding: 10px 20px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}

.web-table-heading {
  padding: 20px !important;
  font-size: 18px !important;
  line-height: 20px;
  color: #fe0000 !important;
  font-weight: 600 !important;
}

.web-table-col {
  padding: 12px 20px !important;
}

.pending-btn,
.expire-btn,
span.enable-btn,
span.disable-btn {
  background-color: #fff9e7;
  color: #f2c94c;
  width: 87px;
  display: block;
  height: 28px;
  border-radius: 10px;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

span.disable-btn {
  color: #eb5757;
  background-color: #ffe8e8;
  border: 1px solid #eb5757;
}

span.enable-btn {
  background-color: #e9fff2;
  color: #219653;
  border: 1px solid #219653;
}

.expire-btn {
  border: 1px solid #939393;
  background-color: #efefef;
  color: #939393;
}

.sold-btn {
  background-color: #fff4f4;
  color: #eb5757;
  border: 1px solid #eb5757;
  width: 87px;
  display: block;
  height: 28px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.table-col .delete-coupon,
.table-col .action-btn {
  background: none;
  border: none;
}

.for-action {
  margin: 0 0 0 15px;
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
}

td.customer-profile {
  padding: 0 15px 0 0;
  width: 50px;
  height: 50px;
}

.customer-profile img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

span.service-rating {
  display: block;
  color: #828282;
  font-weight: 500;
  font-size: 12px;
  margin: 7px 0 0;
  font-family: "Rubik", sans-serif;
}

span.service-rating img {
  margin: 0 7px 0 0;
}

/* table data end */

.next-previous-slide {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 15px 0;
}

.next-previous-slide .slides-heading span {
  font-size: 14px;
  color: #1a1c35;
}

.next-previous-slide .slides-button {
  width: max-content;
}

.next-previous-slide .slides-button ul li {
  display: inline-block;
}

.next-previous-slide .slides-button ul li a {
  width: 28px;
  height: 28px;
  display: inline-block;
  color: #828282;
  border-radius: 10px;
  text-align: center;
  line-height: 28px;
}

.next-previous-slide .slides-button ul li a.active-slide {
  background-color: #1a1c35;
  color: #fff;
}

.dashboard-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 31px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #e6fbf3;
  z-index: 99;
}

.logo-mobile {
  display: none;
}

.logo-mobile a {
  display: inline-block;
}

.logo-mobile a img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

.bar-btn-mobile {
  display: none;
}

.dashboard-menu .logo .tab-menu-bar a.closeBtn-mobile {
  display: none;
}

.bar-btn-mobile span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
}

.dashboard-header .dashboard-heading h2,
.dashboard-header .dashboard-heading a.dashboard-back {
  font-weight: 500;
  font-size: 26px;
  color: #000;
}

.dashboard-header .dashboard-heading a.dashboard-back {
  display: flex;
  align-items: center;
  width: max-content;
}

.dashboard-header .dashboard-heading a.dashboard-back img {
  margin: 0 15px 0 0;
}

.dashboard-header .profile-picture {
  width: auto;
  display: flex;
  align-items: center;
  position: relative;
}

.dashboard-header .profile-picture .profile-img>a {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: #999;
  border-radius: 50%;
}

.dashboard-header .profile-picture .profile-img a img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown {
  width: 254px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 24px;
  right: 47px;
  margin: 60px 0 0;
  opacity: 10;
  /* visibility: hidden; */
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 2%);
  z-index: 999;
  cursor: pointer;
  /* border: 2px solid #1980DA; */
}

.dashboard-header .profile-picture .profile-img .profile-dropdown.dropdown-active {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .profile-info::after {
  content: "";
  position: absolute;
  top: -8px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .profile-info {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
  padding: 18px 0 21px;
  position: relative;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .profile-info h3 {
  font-weight: bold;
  font-size: 18px;
  margin: 13px 0 0;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .profile-info span {
  font-size: 14px;
  color: #828282;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .profile-info .profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin: 0 auto;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .profile-info .profile-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .log-out {
  width: 100%;
  padding: 18px 0 0 20px;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .log-out ul li {
  margin: 0 0 16px;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .log-out ul li a {
  font-weight: 500;
  color: #4f4f4f;
  display: flex;
  width: max-content;
  align-items: center;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .log-out ul li a.profile-btn {
  color: #4f4f4f;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .log-out ul li a.active {
  color: #eb5757;
}

.dashboard-header .profile-picture .profile-img .profile-dropdown .log-out ul li a img {
  margin: 0 11px 0 0;
  width: 20px;
  height: auto;
  border-radius: initial;
}

/* setting page */
.setting-main {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  height: max-content;
  border-radius: 10px;
}

.add-guard-main .setting-main {
  background-color: transparent;
  display: flex;
  gap: 11px;
}

.add-guard-main .setting-main .id-proofs {
  background-color: #fff;
  width: 40%;
  padding: 45px;
}

.add-guard-main .setting-main .setting-details {
  background-color: #fff;
  width: 60%;
}

.add-guard-main .setting-main .setting-details .basic-detail {
  margin: 0;
}

.setting-main .setting-details {
  width: 100%;
  max-width: 864px;
  padding: 40px;
}

.setting-main .setting-details h2 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 8px;
  color: #000;
  font-family: "Open Sans", sans-serif;
}

.setting-main .setting-details p {
  font-size: 14px;
  color: #6b7280;
  line-height: 14px;
  letter-spacing: 1px;
}

.setting-main .setting-details p span {
  font-weight: 500;
}

.customer-detail .setting-details {
  max-width: 990px;
}

.setting-main .setting-details .basic-detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;
  gap: 24px;
}

.setting-main .setting-details .basic-detail .profile-upload {
  width: auto;
  text-align: center;
}

.setting-main .setting-details .basic-detail .profile-upload .profile-img {
  width: 197px;
  height: 197px;
  background-color: #f6f6f6;
  position: relative;
  border-radius: 10px;
}

.setting-main .setting-details .basic-detail .profile-upload .profile-img .input-file {
  width: 42px;
  height: 42px;
  background: #ff3c3c;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
}

.setting-main .setting-details .basic-detail .profile-upload .profile-img .input-file input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.setting-main .setting-details .basic-detail .profile-upload .profile-img .input-file img {
  width: 20px;
}

.setting-main .setting-details .basic-detail .profile-upload .profile-img>img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.setting-main .setting-details .basic-detail .profile-upload label {
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  letter-spacing: 1px;
  margin: 8px 0 0;
  font-weight: 600;
  display: block;
}

.setting-main .setting-details .basic-detail .profile-details {
  width: calc(100% - 197px);
}

.setting-main .setting-details .save-btn {
  margin: 51px 0 0;
  padding: 16px 0 0;
  border-top: 1px solid #9ca3af;
  display: flex;
  align-items: center;
  justify-content: right;
}

.setting-main .setting-details .save-btn button {
  width: 166px;
  height: 48px;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #fff;
  background-color: #ff3c3c;
}

.setting-main .setting-details .basic-detail .profile-details .input-group {
  width: 100%;
  display: flex;
  gap: 24px;
  margin: 0 0 24px;
}

.setting-main .setting-details .basic-detail .profile-details .input-group .input-field {
  width: 50%;
}

.setting-main .setting-details .basic-detail .profile-details .input-group .input-field label {
  display: block;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #9ca3af;
  letter-spacing: 1px;
  margin: 0 0 4px;
}

.setting-main .setting-details .basic-detail .profile-details .input-group .input-field input::placeholder {
  color: #4b5563;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.setting-main .setting-details .basic-detail .profile-details .input-group .input-field input,
.setting-main .setting-details .basic-detail .profile-details .input-group .input-field textarea {
  width: 100%;
  height: 54px;
  border-radius: 10px;
  border: none;
  background-color: #f6f6f6;
  padding: 0 0 0 10px;
  font-size: 14px;
  line-height: 16px;
  color: #4b5563;
  font-weight: bold;
}

.setting-main .setting-details .basic-detail .profile-details .input-group .input-field .password {
  width: 100%;
  position: relative;
}

.setting-main .setting-details .basic-detail .profile-details .input-group .input-field .password button {
  background: none;
  border: none;
  color: #ff3c3c;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

/* .table-data {
    height: 57vh;
    overflow-y: auto;
} */
.member-unavailable-main {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 80vh;
}

.member-unavailable-main p {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  margin: 20px 0 0;
}

.member-unavailable-main .loaction-list-head {
  justify-content: right;
  padding: 17px 26px;
  border-bottom: none;
  height: fit-content;
}

/* web Csss  */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

input,
textarea {
  font-family: Arial, Helvetica, sans-serif;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 70%);
  opacity: 1;
  z-index: 111;
}

.overlay.dropdown-active {
  opacity: 1;
  visibility: visible;
}

.overlay.change-active {
  opacity: 1;
  visibility: visible;
}

/* table data start */
table {
  width: 100%;
  border-collapse: collapse;
}

td {
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  color: #333;
}

.booking-msg {
  width: 48px;
  height: 48px;
  background-color: rgb(213 141 232 / 20%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0 0 25px;
}

.booking-msg .msg-number {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #d58de8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  top: -5px;
  right: -5px;
  font-size: 8px;
}

.status {
  padding: 7px 22px;
  border-radius: 10px;
  background-color: #edff88;
  border: none;
  color: #413740;
  font-weight: 500;
  font-size: 12px;
}

.product-img,
.product-img a {
  width: 35px;
  height: 35px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.product-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

td.table-col {
  padding: 10px 19px;
  font-size: 20px;
  color: #333;
  font-size: 14px;
}

.table-col {
  position: relative;
}

span.rating-icon {
  display: flex;
  gap: 6px;
}

td.for-order {
  padding: 0 15px;
}

td p {
  color: #828282;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0;
  text-align: center;
}

td p img {
  margin: 0 5px 0 0;
}

td i {
  margin: 0 0 0 25px;
  color: #bdbdbd;
  cursor: pointer;
}

/* th.heading {
    color: #FE0000;
    padding: 22px 20px;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
} */

.table-data-main {
  padding: 0 0 0 30px;
  overflow-x: auto;
  background-color: white;
}

.pending-btn,
.expire-btn,
span.enable-btn,
span.disable-btn {
  background-color: #fff9e7;
  color: #f2c94c;
  width: 87px;
  display: block;
  height: 28px;
  border-radius: 10px;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

span.disable-btn {
  color: #eb5757;
  background-color: #ffe8e8;
  border: 1px solid #eb5757;
}

span.enable-btn {
  background-color: #e9fff2;
  color: #219653;
  border: 1px solid #219653;
}

.expire-btn {
  border: 1px solid #939393;
  background-color: #efefef;
  color: #939393;
}

.sold-btn {
  background-color: #fff4f4;
  color: #eb5757;
  border: 1px solid #eb5757;
  width: 87px;
  display: block;
  height: 28px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.table-col .delete-coupon,
.table-col .action-btn {
  background: none;
  border: none;
  margin-left: 20px;
}

.for-action {
  margin: 0 0 0 15px;
  cursor: pointer;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
}

td.customer-profile {
  padding: 0 15px 0 0;
  width: 50px;
  height: 50px;
}

.customer-profile img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

span.service-rating {
  display: block;
  color: #828282;
  font-weight: 500;
  font-size: 12px;
  margin: 7px 0 0;
  font-family: "Rubik", sans-serif;
}

span.service-rating img {
  margin: 0 7px 0 0;
}

.device {
  width: 245px;
  /* position: relative; */
}

.device button {
  width: 100%;
  height: 40px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 15%);
  background-color: #fff;
  border-radius: 5px;
  border: none;
  color: #333;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.device button img {
  position: absolute;
  right: 6px;
}

tbody tr:last-child td .device:last-child .dropdown-model {
  top: auto;
  bottom: 40px;
}

tbody tr:nth-last-child(2) td .device:last-child .dropdown-model {
  top: auto;
  bottom: 40px;
}

tbody tr:nth-last-child(3) td .device:last-child .dropdown-model {
  top: auto;
  bottom: 40px;
}


.device .dropdown-model {
  width: 245px;
  border-radius: 5px;
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 0;
  opacity: 1;
  /* visibility: hidden; */
  height: 305px;
  overflow-y: auto;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.15);
}

.device .dropdown-model.model-active {
  opacity: 1;
  visibility: visible;
}

.device .dropdown-model ul {
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 15%);
  overflow: hidden;
}

.device .dropdown-model ul li {
  padding: 10px 13px;
  cursor: pointer;
}

.device .dropdown-model ul li.select-device {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid transparent;
  border-bottom: 1px solid #e8e2e2;
  font-size: 14px;
}

.device .dropdown-model ul li.select-device:hover {
  border: 1px solid rgba(254, 0, 0, 1);
}

.table-col .yes-no {
  width: 153px;
  display: flex;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 15%);
  justify-content: space-between;
  padding: 5px 15px;
  border-radius: 5px;
  gap: 10px;
  margin: 0;
}

.table-col .yes-no li {
  width: 40px;
  height: 30px;
  font-size: 18px;
  color: #333;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.table-col .yes-no li.active {
  background-color: #fe0000 !important;
  color: #fff !important;
  border-radius: 5px;
}

.table-col .yes-no li.blank {
  background-color: #f6f6f6;
}

/* table data end */

.profile-dropdown {
  width: 254px;
  display: inline-block;
  background-color: #fff;
  position: absolute;
  top: 37px;
  right: 46px;
  margin: 60px 0 0;
  opacity: 1;
  /* visibility: hidden; */
  border-radius: 10px;
  transition: all 0.3s;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 2%);
  z-index: 1111;
  transition: all 0.3s;
  /* border: 2px solid #1980DA; */
}

.profile-dropdown.dropdown-active {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.profile-dropdown .profile-info::after {
  content: "";
  position: absolute;
  top: -8px;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.profile-dropdown .profile-info {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
  padding: 18px 0 21px;
  position: relative;
}

.profile-dropdown .profile-info h3 {
  font-weight: bold;
  font-size: 18px;
  margin: 13px 0 0;
}

.profile-dropdown .profile-info span {
  font-size: 14px;
  color: #828282;
}

.profile-dropdown .profile-info .profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin: 0 auto;
}

.profile-dropdown .profile-info .profile-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.profile-dropdown .log-out {
  width: 100%;
  padding: 18px 0 0 20px;
}

.profile-dropdown .log-out ul li {
  margin: 0 0 16px;
}

.profile-dropdown .log-out ul li a {
  font-weight: 500;
  color: #4f4f4f;
  display: flex;
  width: max-content;
  align-items: center;
}

.profile-dropdown .log-out ul li a.profile-btn {
  color: #4f4f4f;
}

.profile-dropdown .log-out ul li a.active {
  color: #eb5757;
}

.profile-dropdown .log-out ul li a img {
  margin: 0 11px 0 0;
  width: 20px;
  height: auto;
  border-radius: initial;
}

/* logout confirm popup */
.block-main,
.logout-main,
.delete-guest-main,
.guard-vehicle-main {
  width: 100%;
  max-width: 340px;
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 30px 38px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  /* visibility: hidden; */
  z-index: 1111;
}

.delete-guest-main.delete-active {
  opacity: 1;
  visibility: visible;
}

.block-main.block-active {
  opacity: 1;
  visibility: visible;
}

.block-main.customer-block-active {
  opacity: 1;
  visibility: visible;
}

.logout-main.logout-main-active {
  opacity: 1;
  visibility: visible;
}

.block-main.delete-coupon-active {
  opacity: 1;
  visibility: visible;
}

.block-main .delete-icon,
.logout-main .delete-icon,
.delete-guest-main .delete-icon,
.guard-vehicle-main .delete-icon {
  width: auto;
  background: none;
  border: none;
}

.block-main .delete-icon img {
  width: 35px;
  height: 35px;
}

.delete-guest-main .delete-icon img {
  width: 27px;
}

.block-main h2,
.logout-main h2,
.delete-guest-main h2,
.guard-vehicle-main h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin: 12px 0 0;
}
.logout-main h2 {
  margin: 20px 0 0;
}

.block-main p,
.logout-main p,
.delete-guest-main p,
.guard-vehicle-main p {
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  margin: 12px 0;
}

.logout-main p {
  margin: 12px 0 20px;
}

.sales-action {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.sales-action-New {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  max-width:"100px !important"
}

.sales-action .action-btn img {
  transform: scale(0.8);
}

.block-main .block-btn,
.logout .block-btn,
.delete-guest-main .block-btn,
.guard-vehicle-main .block-btn {
  width: 100%;
}

.block-main .vendor-block {
  margin: 18px 0 0;
  display: inline-block;
}

.block-main .block-btn button,
.logout-main .block-btn button,
.delete-guest-main button,
.guard-vehicle-main .block-btn button {
  width: 80px;
  height: 36px;
  color: #413740;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  margin: 0 10px 0 0;
  border: none;
  background: none;
}
.logout-main {
  padding: 18px 30px 30px;
}

.block-main .block-btn button.logout-btn,
.logout-main .block-btn button.logout-btn,
.delete-guest-main .block-btn button.logout-btn,
.guard-vehicle-main .block-btn button.logout-btn {
  background-color: #ff3c3c;
  color: #fff;
}

/* change password popup */
.change-password-main {
  width: 378px;
  border-radius: 10px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 10;
  z-index: 1111;
  padding: 29px 30px 19px 30px;
  transform: translate(-50%, -50%);
}

.change-password-main.change-active {
  opacity: 1;
  visibility: visible;
}

.change-password-main .change-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 43px;
}

.change-password-main .change-head h2 {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

.change-password-main .change-head button {
  background: none;
  border: none;
}

.change-password-main .input-field {
  width: 100%;
  margin: 0 0 30px;
}

.change-password-main .input-field label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  margin: 0 0 8px;
}

.change-password-main .input-field .password {
  width: 100%;
  position: relative;
}

.change-password-main .input-field .password input {
  width: 100%;
  height: 43px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 0 19px;
}

.change-password-main .input-field .password span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 17px;
}

.change-password-main .password-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.change-password-main .password-btn button {
  width: 88px;
  height: 48px;
  border: none;
  background: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 16px;
  color: #ff3c3c;
  letter-spacing: 1px;
  font-weight: bold;
}

.change-password-main .password-btn button.Save-btn {
  background-color: #ff3c3c;
  color: #fff;
}

/* Login pages css start */
#Dashboard-login-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  background-color: #f6f6f6;
  margin: 70px auto;
}

.dashboard-input-left {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding: 117px 0;
}

/* .dashboard-input-left .logo {
    width: 100%;
    margin: 0 0 37px 0;
    text-align: center;
} */

.dashboard-input-left .logo a {
  display: inline-block;
  text-align: center;
  width: max-content;
}

.dashboard-input-left .login-input-details {
  width: 100%;
}

.dashboard-input-left .login-input-details h2 {
  color: #4f4f4f;
  font-size: 32px;
  font-weight: bold;
}

.dashboard-input-left .login-input-details p {
  font-size: 16px;
  color: #4f4f4f;
  margin: 8px 0 34px 0;
}

.dashboard-input-left .login-input-details .input-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
  margin: 0 0 16px 0;
}

.dashboard-input-left .login-input-details .input-group.mb {
  margin-bottom: 12px;
}

.dashboard-input-left .login-input-details .input-group span.icon {
  position: absolute;
  top: 50%;
  left: 21px;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}

.dashboard-input-left .login-input-details .input-group span.eye-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  cursor: pointer;
}

.dashboard-input-left .login-input-details .input-group input {
  width: 100%;
  height: 70px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 0 63px;
  font-size: 16px;
}

.dashboard-input-left .login-input-details .input-group input::placeholder {
  font-size: 16px;
  color: #bdbdbd;
}

.dashboard-input-left .login-input-details .input-group input[type="checkbox"] {
  width: 26px;
  height: 26px;
  display: inline-block;
  accent-color: #1980da;
}

.dashboard-input-left .login-input-details .input-group label {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: normal;
  display: inline-block;
  margin: 0 0 0 11px;
}

.dashboard-input-left .login-input-details .input-group a {
  color: #ff3c3c;
  font-size: 16px;
  margin: 0 0 16px;
}

.dashboard-input-left .login-input-details .login-btn {
  width: 100%;
  margin: 0 0 26px;
}

.dashboard-input-left .login-input-details .forgot-btn {
  margin: 30px 0 0;
}

.dashboard-input-left .login-input-details .save-btn {
  margin: 43px 0 0;
}

.dashboard-input-left .login-input-details .login-btn button {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  border: none;
  background-color: #ff3c3c;
}

.dashboard-input-left .login-input-details .otp-input {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 24px 0;
}

.dashboard-input-left .login-input-details .otp-input input {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
  outline: none;
}

.dashboard-input-left .login-input-details .resend-otp p {
  color: #4f4f4f;
  font-size: 16px;
  text-align: center;
}

.dashboard-input-left .login-input-details .resend-otp p a {
  font-size: 16px;
  margin: 0 0 0 6px;
  color: #ff3c3c;
}

.dashboard-input-left .login-input-details .resend-otp {
  width: 100%;
  text-align: left;
}

.dashboard-input-left .login-back-btn {
  left: 55%;
  position: fixed;
  top: 56px;
  width: auto;
  transform: translateX(-50%);
}

.dashboard-input-left .login-back-btn a {
  width: 40px;
  height: 40px;
  border: 1px solid #fe0000;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* profile details css */

#member-profile-main {
  width: 100%;
  /* max-width: 640px; */
  text-align: center;
  /* margin: 60px auto ; */
  background-color: #fff;
  padding: 0 185px;
  /* height: 100vh; */
}

.profile-main {
  width: 100%;
  display: flex;
  background-color: #f6f6f6;
  justify-content: center;
  padding: 50px 150px;
}

.profile-main h2 {
  font-weight: bold;
  font-size: 28px;
  line-height: 20px;
  color: #000;
  margin: 0 0 82px;
}

.profile-main .profile-input-detail {
  width: calc(100% - 270px);
}

.profile-main .profile-details-main {
  width: 270px;
}

.profile-main .profile-details-main .profile-img {
  width: 213px;
  margin: 0 auto;
  position: relative;
}

.profile-main .profile-details-main .profile-img>img {
  width: 100%;
  height: 213px;
  border-radius: 10px;
  object-fit: cover;
}

.profile-main .profile-details-main .profile-img button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  background-color: #fe0000;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.profile-main .profile-details-main .profile-img button input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.profile-main .profile-details-main label {
  color: #6b7280;
  font-weight: bold;
  font-size: 18px;
  line-height: 12px;
  display: block;
  margin: 10px 0 0;
}

.profile-main .input-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin: 0 0 40px;
}

.profile-main .input-group .input-field {
  width: 50%;
  text-align: left;
}

.profile-main .input-group .input-field label {
  color: #9ca3af;
  font-size: 16px;
  line-height: 12px;
  font-weight: bold;
  display: block;
  margin: 0 0 8px;
}

.profile-main .input-group .input-field input {
  width: 100%;
  height: 54px;
  border-radius: 10px;
  background-color: #fff;
  padding: 0 11px;
  font-size: 18px;
  border: none;
}

.profile-main .input-group .input-field input::placeholder {
  color: #4b5563;
}

.profile-main .input-group .input-field .password {
  position: relative;
}

.profile-main .input-group .input-field .password button {
  background-color: transparent;
  border: none;
  color: #fe0000;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  font-weight: 500;
}

.profile-main .save-btn {
  width: 100%;
  display: flex;
  justify-content: right;
}

.profile-main .save-btn button {
  width: 166px;
  height: 48px;
  background-color: #fe0000;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
}

.back-btn {
  position: fixed;
  top: 44px;
  left: 64px;
}

.back-btn button {
  border: 1px solid #fe0000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #fff;
}

/* dashboard css  */
#dashboard-main {
  width: 100%;
  display: inline-block;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  background-color: #fff;
  z-index: 1;
  position: relative;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
}

.header .logo {
  width: auto;
}

.header .logo a {
  display: inline-block;
}

.header .logo a img {
  width: 150px;
  height: auto;
}

.header .profile {
  width: auto;
  display: flex;
  align-items: center;
}

.header .profile>a {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .profile a img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.select-main {
  width: 100%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  padding: 15px 152px;
  gap: 100px;
}

.select-main .select-box {
  width: auto;
  text-align: center;
}

.select-main .select-box h2 {
  color: #ee0000;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 15px;
  line-height: 22px;
  text-align: left;
}

.select-main .select-box h2 span {
  display: block;
  font-size: 28px;
}

.select-main .select-box ul {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 18px;
  border-radius: 5px;
  gap: 10px;
  margin: 0;
}

.select-main .select-box ul.phone-lines li {
  padding: 5px 15px;
}

.select-main .select-box ul li {
  padding: 5px;
  font-size: 18px;
  color: #333;
  line-height: 16px;
  cursor: pointer;
}

.select-main .select-box ul li.auto-box {
  width: 40px;
  /* height: 30px; */
  background-color: #f6f6f6;
  border-radius: 5px;
}

.select-main .select-box ul li.active {
  background-color: #ee0000;
  color: #fff;
  border-radius: 5px;
}

.total-payout-main {
  /* width: 100%; */
  display: flex;
  justify-content: left;
  gap: 40px;
  padding: 0;
  background-color: transparent;
}

.total-payout-main .total {
  width: 280px;
  /* height: 100px; */
  background-color: #f6f6f6;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.total-payout-main .total .head {
  background-color: #000000;
  padding: 6px 0;
  text-align: center;
}

.total-payout-main .total .head h3 {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
}

.total-payout-main .total b {
  font-weight: bold;
  font-size: 20px;
  color: #ee0000;
  padding: 10px 0;
  display: block;
}

.total-payout-main .total b span {
  font-size: 23px;
}

.breadcrumbs {
  background-color: #fff;
  padding: 30px 50px;
  width: 100%;
  display: inline-block;
}

.breadcrumbs ul {
  display: flex;
  gap: 7px;
  margin: 0;
}

.breadcrumbs ul li a {
  color: #8a898d;
  font-weight: normal;
  font-size: 16px;
}

.breadcrumbs ul li a.active {
  color: #fe0000;
}

.website-login-main {
  max-width: 100% !important;
  margin: 0 !important;
}

.website-login-main .dashboard-input-left {
  padding: 0;
}

#root {
  height: 100vh;
  background-color: #fff;
}

.searchBar {
  width: 100%;
  position: relative;
  display: flex;
  border: none;
  padding: 8px 15px;
  border-radius: 30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 25%);
}

.searchBar input {
  width: 100%;
  border: none;
  font-size: 14px;
}

.searchBar input::placeholder {
  color: #888888;
}

.table-data-dropdown {
  overflow-x: clip;
  vertical-align: top;
  padding: 0 0 30px 30px;
}

/* 16 july and 17 july css */
.loaction-list-main .loaction-list-head {
  border: none;
}

.loaction-list-main .filter-bar-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px 15px 32px;
}

.loaction-list-main .filter-bar-main ul {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0;
}

.loaction-list-main .filter-bar-main ul li select,
.loaction-list-main .filter-bar-main ul li input {
  width: 124px;
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #1a1c35;
  border: 2px solid #1a1c35;
}

.loaction-list-main .filter-bar-main .filter-btn-group {
  width: auto;
  display: flex;
  align-items: center;
  gap: 24px;
}

.loaction-list-main .filter-bar-main .filter-btn-group>div {
  display: flex;
  gap: 10px;
  align-items: center;
}

.loaction-list-main .filter-bar-main .filter-btn-group>div span {
  font-weight: 500;
  font-size: 14px;
  color: #6d6d6d;
}

.loaction-list-main .filter-bar-main .filter-btn-group>div button {
  width: 175px;
  height: 40px;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: #1a1c35;
  justify-content: center;
  align-items: center;
  border: 2px solid #1a1c35;
  border-radius: 10px;
  background-color: #fff;
}

.loaction-list-main .filter-bar-main .filter-btn-group select {
  width: 118px;
  height: 40px;
  background-color: #fff;
  border: 2px solid #1a1c35;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  color: #1a1c35;
  padding: 0 10px;
}

.loaction-list-main .issue-main {
  width: 100%;
  padding: 0 0 90px;
}

.loaction-list-main .issue-main .head {
  width: 100%;
  padding: 15px 35px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 7%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loaction-list-main .issue-main .head h3 {
  font-weight: 500;
  font-size: 22px;
  color: #000;
}

.loaction-list-main .issue-main .head button {
  background-color: transparent;
  border: none;
}

.loaction-list-main .issue-main .issue-details-main {
  width: 100%;
  padding: 35px;
}

.loaction-list-main .issue-main .issue-details-main .input-group {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 25px;
  margin: 0 0 25px;
}

.loaction-list-main .issue-main .issue-details-main .input-group .input-field {
  width: 25%;
}

.loaction-list-main .issue-main .issue-details-main .input-group .input-field span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 14px;
  color: #4b5563;
}

.loaction-list-main .issue-main .issue-details-main .input-group .input-short {
  width: calc(100% - 25%);
}

.loaction-list-main .issue-main .issue-details-main .input-group .input-text {
  width: 100%;
}

.loaction-list-main .issue-main .issue-details-main .input-group .input-field label {
  font-weight: 600;
  font-size: 14px;
  display: block;
  color: #757575;
  letter-spacing: 1px;
  margin: 0 0 12px;
}

.loaction-list-main .issue-main .issue-details-main .input-group .input-field input,
.loaction-list-main .issue-main .issue-details-main .input-group .input-text textarea {
  width: 100%;
  height: 60px;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 0 13px;
  color: #4b5563;
  font-size: 16px;
  border: none;
}

.loaction-list-main .issue-main .issue-details-main .input-group .input-text textarea {
  height: 88px;
  padding: 16px 24px;
}

.upload-media {
  width: 100%;
  display: flex;
  gap: 42px;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs {
  width: auto;
  display: flex;
  gap: 20px;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs {
  display: block !important;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs-view {
  display: flex;
  gap: 30px;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images video,
.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images img {
  height: 178px !important;
  width: 100% !important;
  object-fit: cover;
  border-radius: 5px;
  margin: 0 0 10px;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .paste-ss {
  width: auto;
  display: flex;
  gap: 10px;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images {
  width: 25%;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images label,
.loaction-list-main .issue-main .issue-details-main .uploaded-imgs label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  margin: 0 0 11px;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images b {
  margin: 0 0 14px;
  display: block;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images span {
  width: 100%;
  height: 178px;
  margin: 0 0 15px;
  display: inline-block;
}

.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images span img,
.loaction-list-main .issue-main .issue-details-main .uploaded-imgs .images span video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

/* filter popup */
/* .filter-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 50%);
    z-index: 111;
    display: none;
}
.filter-overlay.filter-active {
    display: unset;
}
.filter-main {
    width: 500px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11;
    background-color: #fff;
    height: 100vh;
}
.filter-main .head {
    width: 100%;
    display: flex;
    padding: 25px 30px;
    justify-content: space-between;
    box-shadow: 0 1px 0 rgb(0 0 0 / 7%);
}
.filter-main .head h3 {
    font-weight: 500;
    font-size: 20px;
    color: #333;
}
.filter-main .head button {
    background-color: transparent;
    border: none;
}
.filter-main .details {
    padding: 21px 30px;
    width: 100%;
}
.filter-main .details .input-field {
    width: 100%;
    margin: 0 0 23px;
}
.filter-main .details .input-date {
    width: 100%;
    display: flex;
    gap: 16px;
}
.filter-main .details .input-date > div {
    width: 50%;
}
.filter-main .details .input-field label {
    margin: 0 0 12px;
    color: #9CA3AF;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 12px;
    display: block;
}
.filter-main .details .input-field select,
.filter-main .details .input-field input {
    width: 100%;
    height: 48px;
    border:1px solid #D7D7D7;
    border-radius: 9px;
    background-color: #fff;
    padding: 0 15px;
    font-size: 16px;
    color: #4B5563;
}
.filter-main .btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 25px 30px;
    background-color: #fff;
    border-top: 1px solid #F2F2F2;
    position: absolute;
    bottom: 0;
    left: 0;
}
.filter-main .btn-group  button {
    width: 100px;
    height: 36px;
    font-size: 14px;
    font-weight: bold;
    background-color: #E0E0E0;
    color: #828282;
    border-radius: 10px;
    border: none;
}
.filter-main .btn-group  button.apply-btn {
    background-color: #FE0000;
    color: #fff;
} */

.permission-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 50%);
  z-index: 111;
  display: block;
  /* display: none; */
}

.permission-main {
  width: 500px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 94px;
  border-radius: 11px;
}

.permission-main .close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 30px;
  right: 20px;
}

.permission-main .head {
  width: 100%;
  text-align: center;
  margin: 0 0 30px;
}

.permission-main .head h3 {
  font-weight: 600;
  font-size: 20px;
  color: #333;
}

.permission-main .details {
  width: 100%;
}

.permission-main .details .profile {
  width: 100%;
  display: flex;
  gap: 14px;
}

.permission-main .details .profile .img {
  width: 100px;
  height: 100px;
  background-color: #f2f2f2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.permission-main .details .profile .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.permission-main .details .profile .username {
  width: calc(100% - 100px);
}

.permission-main .details .profile .username span {
  font-size: 11px;
  color: #b4b4b4;
}

.permission-main .details .profile .username h4 {
  font-style: 16px;
  color: #4b5563;
}

.permission-main .details .calc-show {
  width: 100%;
  margin: 30px 0 40px;
  display: flex;
  gap: 20px;
}

.sheet-main .yes-no {
  width: 100% !important;
}

.permission-main .details .calc-show .yes-no {
  width: 50%;
}

.permission-main .details .calc-show .yes-no span {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  color: #9ca3af;
  display: block;
  margin: 0 0 12px;
}

.permission-main .details .calc-show .yes-no .select-btn {
  width: 100%;
  display: flex;
  gap: 7px;
}

.permission-main .details .calc-show .yes-no .select-btn button {
  width: 50%;
  height: 32px;
  background-color: #f6f6f6;
  color: #4b5563;
  font-weight: bold;
  font-size: 10px;
  border: 1px solid #f6f6f6;
  border-radius: 6px;
}

.permission-main .details .calc-show .yes-no .select-btn .active {
  background-color: #fff3f3;
  border: 1px solid #fe0000;
  color: #fe0000;
}

.permission-main .details .btn-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.permission-main .details .btn-group button {
  width: 50%;
  height: 48px;
  background-color: #fff3f3;
  border: none;
  color: #fe0000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 8px;
}

.permission-main .details .btn-group .save-btn {
  background-color: #fe0000;
  color: #fff;
}

/* dropDown select Box  */
.dropdown-list-main {
  width: 100%;
  position: absolute;
  border-radius: 5px;
  top: 50px;
  left: 0;
  background-color: #fff;
  z-index: 11;
  border: 1px solid #e9e0e0;
}

.dropdown-list-main ul li {
  padding: 14px 20px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.dropdown-list-main ul li span {
  font-weight: 500;
  font-size: 16px;
  color: #333;
}

/* user info popup */
#Dashboard-login-main {
  background-color: #f6f6f6;
}

.user-info-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 50%);
  z-index: 9999;
  display: block;
}

.user-info-main {
  width: 990px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
}

.user-info-main .head {
  width: 100%;
  padding: 25px 0;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 7%);
  text-align: center;
}

.user-info-main .head h3 {
  font-weight: 500;
  font-size: 22px;
  color: #333;
}

.user-info-main button.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
}

.user-info-main .info-details {
  width: 100%;
  display: flex;
  padding: 41px;
  gap: 35px;
}

.user-info-main .info-details .profile {
  width: 145px;
  height: 145px;
  background-color: #f2f2f2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-info-main .info-details .profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.user-info-main .info-details .basic-info {
  width: calc(100% - 145px);
}

.user-info-main .info-details .basic-info .input-group {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.user-info-main .info-details .basic-info .input-group .input-field {
  width: 48%;
  margin: 0 0 15px;
}

.user-info-main .info-details .basic-info .input-group .input-field label {
  display: block;
  color: #757575;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  margin: 0 0 12px;
}

.user-info-main .info-details .basic-info .input-group .input-field input {
  width: 100%;
  height: 60px;
  background-color: #f6f6f6;
  border-radius: 10px;
  border: none;
  padding: 0 15px;
  color: #4b5563;
  font-size: 16px;
}

.user-info-main .info-details .basic-info .input-group .input-field span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #4b5563;
  font-size: 16px;
}

.dashboard-header .dashboard-heading h2 {
  font-weight: 600;
  font-size: 24px;
}

::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}

.incident-main-table {
  width: 100%;
  overflow-x: auto;
}

.incident-main-table .table-data {
  width: 1700px;
}

.web-setting-details .setting-details {
  max-width: 100%;
}

.web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 16px;
  color: #8b8b8b;
}

.web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field input {
  background-color: #fff;
  color: #333;
  font-size: 16px;
  border: 1px solid #d7d7d7;
}

.web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field label {
  font-weight: 600;
  font-size: 14px;
  color: #9ca3af;
  letter-spacing: 1px;
  margin: 0 0 12px;
}

.web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field .position-dropdown button {
  background-color: #fff;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #d7d7d7;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-radius: 10px;
  height: 54px;
}

.web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field .position-dropdown .dropdown-list-main {
  height: 180px;
  overflow-y: auto;
}

.web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field .position-dropdown .dropdown-list-main ul li span {
  position: static;
  transform: unset;
}

.web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field {
  width: 33%;
}

.loaction-list-main .incident-select-head {
  padding: 0 32px 0 0;
}

.loaction-list-main .loaction-list-head .incident-select {
  display: flex;
  margin: 0;
}

.loaction-list-main .loaction-list-head .incident-select li {
  padding: 18px 27px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: #828282;
}

.loaction-list-main .loaction-list-head .incident-select li.active {
  color: #1a1c35;
  background-color: #eaebff;
  border-bottom: 4px solid #1a1c35;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field .incident-dropdown {
  position: relative;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field .incident-dropdown in {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field .incident-dropdown .dropdown-incident {
  position: absolute;
  top: 45px;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 15%);
  padding: 20px 20px 0;
  z-index: 1;
  overflow-y: auto;
  height: auto;
  max-height: 374px;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field .incident-dropdown .dropdown-incident .search {
  position: relative;
  margin: 0 0 20px;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field .incident-dropdown .dropdown-incident .search input {
  height: 35px;
  font-weight: 500;
  font-size: 11px;
  color: #000;
  padding: 0 35px;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field .incident-dropdown .dropdown-incident .search span {
  position: absolute;
  left: 15px;
  width: max-content;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field .incident-dropdown .dropdown-incident ul li {
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  margin: 0 0 20px;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field input,
.loaction-list-main .issue-main .new-incident-main .input-group .input-field select,
.loaction-list-main .issue-main .new-incident-main .input-group .input-text textarea,
.loaction-list-main .issue-main .new-incident-main .input-group .input-field button {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  height: 45px;
  font-size: 16px;
  width: 100%;
  border-radius: 10px;
  padding: 0 15px;
  color: #000;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-field input::placeholder {
  color: #bdbdbd;
}

.loaction-list-main .issue-main .new-incident-main .input-group .input-text textarea {
  height: 110px;
  padding: 15px;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images {
  width: 176px;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss {
  width: 100%;
  height: 132px;
  border-radius: 10px;
  border: 2px dashed #898989;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss button.delete-btn {
  position: absolute;
  top: 5px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  right: 5px;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss button.delete-btn svg {
  width: 25px;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss button {
  background-color: transparent;
  border: none;
}

.loaction-list-main .issue-main .new-incident-main .uploaded-imgs .images .upload-ss button .plusicon {
  width: 20px !important;
  height: 20px !important;
}

.loaction-list-main .issue-main .new-incident-main .submit-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 25px 0 0;
  border-top: 1px solid #d7d7d7;
}

.loaction-list-main .issue-main .new-incident-main .submit-btn button {
  width: 100px;
  height: 44px;
  background-color: #fe0000;
  color: #fff;
  border-radius: 9px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  margin: 14px 0 0;
}

.web-signUp-main {
  background-color: #fff !important;
  text-align: center;
}

.web-signUp-main .dashboard-input-left {
  text-align: center;
}

.web-signUp-main .dashboard-input-left .login-input-details h2 {
  font-size: 25px;
  font-weight: 600;
  line-height: 26px;
}

.web-signUp-main .dashboard-input-left .login-input-details .input-group {
  gap: 15px;
  position: relative;
}

.web-signUp-main .dashboard-input-left .login-input-details .input-group .input-field {
  position: relative;
}

.web-signUp-main .dashboard-input-left .login-input-details .input-group .input-field input {
  padding-right: 15px;
}

.web-signUp-main .dashboard-input-left .login-input-details .input-group input {
  height: 50px;
}

.web-signUp-main .dashboard-input-left .login-input-details .login-btn button {
  height: 55px;
}

.web-signUp-main .dashboard-input-left .login-input-details .have-account {
  width: 100%;
}

.web-signUp-main .dashboard-input-left .login-input-details .have-account p {
  color: #333;
  font-size: 16px;
}

.web-signUp-main .dashboard-input-left .login-input-details .have-account p a {
  color: #ee0000;
}

.web-signUp-main .dashboard-input-left .login-input-details p {
  margin: 8px 0 20px 0;
}

.web-signUp-main .dashboard-input-left .login-input-details .input-group button {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 0 15px 0 63px;
  color: #333;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

/* input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* calculator page css */
.select-main {
  width: 100%;
  background-color: #e6fbf3;
  display: flex;
  justify-content: space-evenly;
  padding: 0 0 25px;
  gap: 45px;
}

.select-main .select-box {
  width: auto;
  text-align: center;
}

.select-main .select-box h2 {
  color: #ee0000;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 16px;
}

.select-main .select-box ul {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 18px;
  border-radius: 5px;
  gap: 10px;
}

.select-main .select-box ul.arrow-btns {
  background-color: transparent;
  padding: 0;
  align-items: center;
  border-radius: 0;
}

.select-main .select-box ul.arrow-btns li.next-btn {
  transform: rotate(180deg);
}

.select-main .select-box ul.arrow-btns li.next-btn,
.select-main .select-box ul.arrow-btns li.previous-btn {
  padding: 0;
}

.select-main .select-box ul.arrow-btns li.number-count {
  padding: 14px 20px;
  background-color: #ee0000;
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  border-radius: 6px;
}

.select-main .select-box ul.phone-lines li {
  padding: 5px 15px;
}

.select-main .select-box ul li {
  padding: 5px;
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.select-main .select-box ul li.auto-box {
  width: 40px;
  height: 30px;
  background-color: #f6f6f6;
  border-radius: 5px;
}

.select-main .select-box ul li.active {
  background-color: #ee0000;
  color: #fff;
  border-radius: 5px;
}

.total-payout-main {
  /* width: 100%; */
  display: flex;
  justify-content: left;
  gap: 40px;
  padding: 0;
}

.total-payout-main .total {
  width: 215px;
  height: auto;
  background-color: #f6f6f6;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.total-payout-main .total .head {
  background-color: #000000;
  padding: 6px 0;
  text-align: center;
}

.total-payout-main .total .head h3 {
  color: #fff;
  font-weight: bold;
  font-size: 22px;
}

.total-payout-main .total b {
  font-weight: bold;
  font-size: 50px;
  color: #ee0000;
  padding: 0 0;
  display: block;
}

.device {
  width: 245px;
  position: relative;
  padding: 0 5px;
}

.device button {
  width: 100%;
  height: 48px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 15%);
  background-color: #fff;
  border-radius: 5px;
  border: none;
  color: #333;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  position: relative;
}

.device .dropdown-model {
  width: 100%;
  border-radius: 5px;
  z-index: 1;
  background-color: #fff;
  position: absolute;
  top: 0;
  /* opacity: 0;
    visibility: hidden; */
}

.table-col .yes-no {
  width: 153px;
  display: flex;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 15%);
  justify-content: space-between;
  padding: 9px 15px;
  border-radius: 5px;
  gap: 10px;
}

.table-col .yes-no li {
  width: 40px;
  height: 30px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #333;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.table-col .yes-no li.active {
  background-color: #fe0000 !important;
  color: #fff !important;
  border-radius: 5px;
}

.table-col .yes-no li.blank {
  background-color: #f6f6f6;
}

.webtable-col {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
}

.select-main .select-box h2 {
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.select-main .select-box h2 span {
  display: block;
  font-weight: bold;
  font-size: 28px;
  color: #ee0000;
}

.calculator-data-main {
  padding: 0 65px;
}

.calculator-list-main {
  width: 100%;
  position: relative;
}

.calculator-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 24, 24, 0.25);
  backdrop-filter: blur(10px);
  z-index: 9;
}

.permission-denied-main {
  width: 100%;
  max-width: 340px;
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 30px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1111;
}

.permission-denied-main .delete-icon {
  width: auto;
  background: none;
  border: none;
}

.permission-denied-main .delete-icon img {
  width: 27px;
}

.permission-denied-main h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin: 12px 0 0;
}

.permission-denied-main p {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 16px 0;
}

.permission-denied-main .ok-btn {
  width: 100%;
}

.permission-denied-main .ok-btn button {
  width: 80px;
  height: 36px;
  color: #fff;
  background-color: #fe0000;
  font-weight: bold;
  font-size: 14px;
  border-radius: 10px;
  border: none;
}

/* calculator page css end*/

/* toast succes incident */
.incident-success-main {
  width: 332px;
  background: linear-gradient(to right, #fe0000, #1a1c35);
  position: absolute;
  top: 10%;
  right: 5%;
  z-index: 111;
  display: flex;
  border-radius: 16px;
  gap: 20px;
  padding: 27px;
  display: none;
}

.incident-success-main .close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 27px;
  right: 27px;
}

.incident-success-main span {
  width: 32px;
  height: 32px;
  background-color: #00830d;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.incident-success-main .detail {
  width: calc(100% - 32px);
}

.incident-success-main .detail h3 {
  font-weight: 600;
  font-size: 19px;
  color: #fff;
}

.incident-success-main .detail p {
  color: #fff;
  margin: 6px 0 0;
}

.setting-main .setting-details .basic-detail .profile-details .input-group .input-field .password-change {
  width: 100%;
  position: relative;
}

.setting-main .setting-details .basic-detail .profile-details .input-group .input-field .password-change button {
  background: none;
  border: none;
  color: #ff3c3c;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.incident-user-info {
  width: 100%;
  background-color: #fff;
  margin: 50px 0 20px;
  overflow: hidden;
  border-radius: 10px;
}

.incident-user-info .head {
  width: 100%;
  padding: 25px 47px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 7%);
}

.incident-user-info .head h3 {
  font-weight: 500;
  font-size: 22px;
  color: #333;
}

.incident-user-info .info {
  width: 100%;
  display: flex;
  gap: 50px;
  padding: 40px;
}

.incident-user-info .info .profile {
  width: 197px;
  height: 197px;
}

.incident-user-info .info .profile img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.incident-user-info .info .details {
  width: calc(100% - 197px);
}

.incident-user-info .info .details .input-group {
  width: 100%;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.incident-user-info .info .details .input-group .input-field {
  width: 31%;
}

.incident-user-info .info .details .input-group .input-field label {
  display: block;
  margin: 0 0 12px;
  font-weight: 600;
  font-size: 14px;
  color: #757575;
}

.incident-user-info .info .details .input-group .input-field input {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 0 12px;
  font-size: 16px;
  color: #4b5563;
  border: none;
}

.incident-user-info .info .details .input-group .input-field span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #4b5563;
  font-size: 16px;
}

.page-number {
  width: auto;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}

.page-number svg {
  width: 20px;
  height: 20px;
}

.page-number .up-dropdown {
  position: absolute;
  top: -205px;
  width: 200px;
  border-radius: 10px;
  right: -180px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 15%);
  overflow: hidden;
}

.page-number .up-dropdown ul {
  margin: 0;
}

.page-number .up-dropdown ul li {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.action-main-new {
  display: none;
  position: absolute;
  top: 0;
  left: -148px;
}

.action_btn:hover+.action-main-new {
  display: unset;
}

.active-main-two {
  top: -30px;
}

.action-main-new:hover {
  display: unset;
}

.InovuaReactDataGrid__row--odd {
  z-index: 111 !important;
}

.InovuaReactDataGrid__row--even:hover {
  z-index: 1111 !important;
}

.web-add-user {
  width: auto;
  display: flex;
  gap: 18px;
}

.image-modal-content {
  height: 85vh !important;
  left: 50%;
  padding: 20px;
  transform: translateX(-50%);
}

/* .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--first .InovuaReactDataGrid__cell {
    height: 80px !important;
    min-height: 80px !important;
}
.InovuaReactDataGrid__row:not(.InovuaReactDataGrid__row--virtualize-columns)>.InovuaReactDataGrid__row-cell-wrap{
    height: 80px  !important;
}
.InovuaReactDataGrid__row--odd,
.InovuaReactDataGrid__row--even {
    height: 80px !important;
}
.InovuaReactDataGrid__row {
    z-index: 1111 !important;
} */

.InovuaReactDataGrid__row:last-child .InovuaReactDataGrid__row-cell-wrap .InovuaReactDataGrid__cell .InovuaReactDataGrid__cell__content .action-main-new {
  top: -40px;
}

.new-upload-media {
  width: 100%;
  display: flex;
  gap: 40px;
  margin: 27px 0 0;
}

.new-upload-media .drag-main {
  width: 363px;
}

.new-upload-media .drag-main .drag-head {
  width: 100%;
  margin: 0 0 11px;
}

.new-upload-media .drag-main .drag-head label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
}

.new-upload-media .drag-main .drag-head label span {
  color: #BDBDBD;
  font-weight: normal;
  font-size: 10px;
}

.new-upload-media .drag-main .drag {
  width: 100%;
  height: 132px;
  position: relative;
  border-radius: 9px;
  margin: 0 0 12px;
  text-align: center;
  border: 2px dashed #898989;
}

.new-upload-media .drag-main .drag>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.new-upload-media .drag-main .drag .delete-btn {
  width: 57px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #FE0000;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

.new-upload-media .drag-main .drag .details {
  width: auto;
}

.new-upload-media .drag-main .drag .details h4 {
  font-weight: normal;
  font-size: 20px;
}

.new-upload-media .drag-main .drag .details span {
  font-weight: normal;
  font-size: 14px;
  display: block;
  margin: 8px 0 14px;
}

.new-upload-media .drag-main .drag .details .drag-or-paste {
  width: auto;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.new-upload-media .drag-main .drag .details .drag-or-paste button {
  width: 54px;
  height: 28px;
  background-color: #FE0000;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
  cursor: pointer;
}

.new-upload-media .drag-main .drag .details .drag-or-paste button input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.new-upload-media .drag-main .drag .details .drag-or-paste span {
  font-size: 14px;
  font-weight: normal;
}

.calculator-parent {
  width: 100%;
  position: relative;
  height: 80vh;
}

.sheet-select {
  display: flex;
  gap: 13px;
}

.sheet-select li {
  display: flex;
  gap: 5px;
  align-items: center;
}

.sheet-select li input {
  width: 18px;
  height: 18px;
  accent-color: #FE0000;
  border-radius: 3px;
  border: 2px solid #FE0000;
}

.sheet-select li label {
  font-weight: 600;
  font-size: 16px;
}


.add-plan-main {
  height: 80vh;
  overflow: visible  !important;
  margin: 0 0 20px;
}

.add-plan-main .loaction-list-head {
  padding: 20px 40px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 7%);
}

.add-plan-main .loaction-list-head h3 {
  font-weight: 500;
  font-size: 20px;
}

.add-plan-main .add-plan-detail {
  width: 100%;
  padding: 40px;
}

.add-plan-main .add-plan-detail .input-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
}

.add-plan-main .add-plan-detail .input-group .input-field {
  width: 100%;
  position: relative;
}

.add-plan-main .add-plan-detail .input-group .input-field label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #4d4c4c;
  margin: 0 0 13px;
}

.add-plan-main .add-plan-detail .input-group .input-field button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-plan-main .add-plan-detail .input-group button,
.add-plan-main .add-plan-detail .input-group .input-field input {
  width: 100%;
  height: 50px;
  border-radius: 11px;
  padding: 0 10px;
  color: #4B5563;
  font-size: 16px;
  background-color: #F6F6F6;
  border: none;
}
.add-plan-main .add-plan-detail .input-group button {
  background-color: #fff;
  border: 1px solid #E0E0E0;
  height: 45px;
}

.add-plan-main .add-plan-detail .input-group .input-field button.active,
.add-plan-main .add-plan-detail.active .input-group .input-field input {
  border: 1px solid #4B5563;
  background-color: transparent;
}

.add-plan-main .view-plan .input-group .input-field input {
  background-color: #F6F6F6;
  border: none;
}

/* add carrier popup */
.carrier-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 70%);
  z-index: 100;
}

.add-carrier-main {
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px;
}

.add-carrier-main .head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-carrier-main .head button {
  background-color: transparent;
  border: none;
}

.add-carrier-main .head h3 {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.add-carrier-main .input-field {
  width: 100%;
  margin: 20px 0 45px;
}

.add-carrier-main .input-field label {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  display: block;
  margin: 0 0 8px;
}

.add-carrier-main .input-field input {
  width: 100%;
  height: 50px;
  padding: 0 10px;
  border: 1px solid #B8B8B8;
  border-radius: 10px;
  font-size: 16px;
  color: #4B5563;
}
/* .react-datepicker-wrapper {
  width: 100% !important;
  height: 50px !important;
  padding: 0 10px;
  border: 1px solid #B8B8B8;
  border-radius: 10px;
  font-size: 16px;
  color: #4B5563;
} */

.add-carrier-main .btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-carrier-main .btns button {
  color: #FE0000;
  font-size: 16px;
  height: 45px;
  font-weight: bold;
  padding: 0 24px;
  border-radius: 8px;
  background-color: #fff;
  border: none;
}

.add-carrier-main .btns button.save-btn {
  background-color: #FE0000;
  color: #fff;
}


/* type search bar */
.carrier-search-main {
  position: absolute;
  padding: 15px;
  width: 100%;
  top: 84px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 15%);
  background-color: #fff;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.carrier-search-main.active {
  opacity: 1;
  visibility: visible;
}

.carrier-search-main .search-bar {
  width: 100%;
  position: relative;
}

.carrier-search-main .search-bar button {
  position: absolute;
  width: auto !important;
  height: auto !important;
  border: none !important;
  top: 8px;
  right: 0;
  color: #EE0000 !important;
  font-weight: 500;
  font-size: 14px !important;
  background-color: transparent !important;
}

.carrier-search-main .search-bar input {
  height: 35px !important;
  font-size: 10px !important;
  border: 1px solid #E0E0E0 !important;
  background-color: #FDFDFD !important;
  color: #333;
}

.carrier-search-main .search-bar input::placeholder {
  color: #BDBDBD;
}

.carrier-search-main ul {
  margin: 20px 0 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.carrier-search-main ul li:last-child {
  /* display: none; */
}

.carrier-search-main ul li {
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.delete-main {
  width: 100%;
  max-width: 340px;
  background-color: #fff;
  border-radius: 10px;
  padding: 18px 30px 38px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  visibility: visible;
  z-index: 1111;
}

.delete-main .delete-icon {
  width: auto;
  background: none;
  border: none;
}

.delete-guest-main .delete-icon img {
  width: 27px;
}

.delete-main h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin: 12px 0 0;
}


.delete-main p {
  font-size: 14px;
  line-height: 18px;
  color: #4f4f4f;
  margin: 12px 0;
}

.delete-main .block-btn {
  width: 100%;
}

.delete-main .block-btn button {
  width: 80px;
  height: 36px;
  color: #413740;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  margin: 0 10px 0 0;
  border: none;
  background: none;
}

.delete-main .block-btn button {
  color: #FF3C3C;
}

.delete-main .block-btn button.logout-btn {
  background-color: #FF3C3C;
  color: #fff;
}

.loaction-list-main .sales-entry-head .incident-select li {
  width: 150px;
  text-align: center;
  font-size: 18px;
}

.sales-entry-table-head {
  padding: 0;
}

.sales-entry-table-head .table-data {
  width: 100%;
  height: 65vh;
  overflow-y: auto;
}

/* .web-sales-entry .table-data {
  min-width: 1500px;
}  */

.sales-loader {
  width: 100%;
  height: 65vh !important;
}

.col-boxes-main {
  background-color: transparent !important;
}

.col-boxes-main {
  background-color: transparent !important;
}

.col-boxes {
  width: 100%;
  display: grid;
  gap: 70px;
  grid-template-columns: repeat(2, 1fr);
}

.col-boxes .col-item {
  width: 100%;
  background-color: #FFEAEA;
  color: #EE0000;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 90px 0;
  cursor: pointer;
  line-height: 89px;
  border-radius: 10px;
}

.web-sales-entry th.heading {
  font-weight: 500 !important;
}

.web-sales-main .loaction-list-head .search-bar input {
  background-color: #FDFDFD;
}

.activation-main .add-plan-detail .input-group .input-field input::placeholder {
  color: #929292;
}

.activation-main .add-plan-detail .input-group .input-field input,
.activation-main .add-plan-detail .input-group .input-field button {
  height: 45px;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  background-color: #fff;
  color: #000;
}
.activation-main .add-plan-detail .input-group .input-field .input-wrapper input{
  padding-left: 25px;
}

.activation-main .add-plan-detail {
  padding: 20px 40px;
}

.activation-main {
  height: 100%;
}

.activation-main .add-plan-detail .input-group .input-field label {
  font-weight: 600;
}

.activation-main .add-plan-detail .input-group .check-field {
  display: flex;
  gap: 28px;
}

.activation-main .add-plan-detail .input-group .input-field .check-input {
  display: flex;
  gap: 15px;
  align-items: center;
}

.activation-main .add-plan-detail .input-group .input-field .check-input label {
  margin: 0;
}

.activation-main .add-plan-detail .input-group .input-field .check-input input {
  width: 18px;
  height: 18px;
  accent-color: #FE0000;
}

.activation-main .add-plan-detail .submit-btns {
  border-top: 1px solid #D7D7D7;
  padding: 14px 0 0;
  margin: 85px 0 0;
  display: flex;
  justify-content: right;
  gap: 20px;
}

.activation-main .add-plan-detail .submit-btns button {
  height: 45px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  background-color: #FAFAFA;
  color: #FE0000;
  width: 145px;
  border: none;
}

.activation-main .add-plan-detail .submit-btns button.save-btn {
  width: 100px;
  background-color: #FE0000;
  color: #fff;
}

.activation-main-new {
  height: auto;
  margin: 0 0 12px;
}

.activation-main-new .add-plan-detail {
  padding: 20px 40px 40px;
}

.activation-main-new .loaction-list-head button {
  background-color: transparent;
  border: none;
  color: #FE0000;
  font-size: 16px;
}

.sales-entry-table-head td.table-col {
  padding: 20px;
}

.sales-entry-table-head th.heading {
  padding: 15px 20px;
}

.carrier-search-main ul {
  max-height: 135px;
  height: auto;
  overflow-y: auto;
}

.carrier-search-main ul {
  max-height: 135px;
  overflow-y: scroll;
}

/* Hide the scroll arrows for WebKit browsers (Chrome, Safari) */
.carrier-search-main ul::-webkit-scrollbar {
  width: 8px;
}

.carrier-search-main ul::-webkit-scrollbar-button {
  display: none;
}

.carrier-search-main ul::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.carrier-search-main ul::-webkit-scrollbar-track {
  background: transparent;
}


.add-plan-detail .carrier-search-main ul {
  margin: 0;
  gap: 15px;
}
.add-plan-detail .carrier-search-main .search-bar {
  margin: 0 0 15px;
}
.react-datepicker__navigation--previous {
  left: 22px  !important;
  top: 19px !important;
}

.react-datepicker__navigation--next {
  right: -1px !important;
  top: 17px !important;
}
.react-datepicker__navigation-icon--next::before {
  width: 10px !important;
  height: 10px !important;
}
.react-datepicker__navigation-icon--previous::before {
  border-width: 3px 3px 0 0 !important;
  width: 6px !important;
  height: 6px !important;
}
.react-datepicker__navigation-icon--previous {
  right: -4px  !important;
}

.react-datepicker-popper {
  z-index: 999  !important;
}

.add-plan-main .loaction-list-head .remove-btn {
  color: #FE0000;
  background-color: transparent;
}
.required {
  color: red;
  font-size: 18px;
  position: relative;
  top: 0;
}

.web-sales-entry {
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc transparent; /* For Firefox */
}

/* For Webkit browsers (Chrome, Safari) */
.web-sales-entry::-webkit-scrollbar {
  width: 8px;
}

.web-sales-entry::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.web-sales-entry::-webkit-scrollbar-track {
  background: transparent;
}
.css-1b1fjlj-MuiFormControl-root-MuiTextField-root{
  width: 100% !important;
}
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root{
  border: 1px solid #4B5563 !important;
  border-radius: 11px !important;
}
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input{
border: none !important;
}
.file-button{
  position: relative;
display: flex;
gap: 15px;
}
.file-button .upload-file_button{
  border: 1px solid #FE0000;
  position: relative;
    border-radius: 10px !important;
    color: #FE0000;
    background: transparent;
    width: 110px;
    height: 36px;
}
.upload_loader {
  border: 2px solid #FE0000;
  border-top-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  width: 15px;
  height: 15px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

.file-button .file-input {
 
  padding: 0 10px;
  height: 36px;
  border: 1px solid #333;
  border-radius: 10px;
  position: relative;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
      position: relative;
        justify-content: space-between;
        gap: 20px;
}

.file-button .file-input input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.add-plan-main .add-plan-detail .input-group .input-field.datepicker-wrapper input {
  outline: none !important;
}

.add-plan-main .add-plan-detail .input-group .input-field.datepicker-wrapper input:focus,
.add-plan-main .add-plan-detail .input-group .input-field.datepicker-wrapper input:hover {
  outline: none !important;
}

.add-plan-main .add-plan-detail .input-group .input-field.datepicker-wrapper button {
  border: none;
}
.css-yxqbup .css-15guoxn{
  position: absolute !important;
  right: 35px;
  width: unset !important;
}

.css-1oy18r0 .css-5v2ak0 {
  border: none;
  border-style: none !important;
}
.data-table-row{
  border-bottom: 1px solid rgb(237, 237, 237);
}
.data-table-row td{
  padding: 20px;
}
.data-table-row .action-btn {
  background: none;
  border: none;
  margin-left: 20px;
}

/* .add-plan-main .add-plan-detail .input-group .input-field:first-child .carrier-search-main {
  bottom: -167px;
} */
.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.dollar-symbol {
  position: absolute;
  left: 10px;
  /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #333;
}

  .input-field input {
    padding-left: 25px;
  }

  .select-button{
    width: 100%;
height: 54px;
border-radius: 10px;
background: transparent;
color: #333;
  font-size: 16px;
  border: 1px solid #d7d7d7;
  }
  .add-customer-button{
    margin-top: 25px;
      background: #FE0000;
      width: 200px;
      padding: 10px;
      border: none;
      border-radius: 10px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
  }
.columns-dropdown {
    display: none;
    position: absolute;
    margin-top:10px;
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    width: 250px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    top:70%;
    right: 3%;
  }
 
  .columns-dropdown.show {
    display: block;
  }
 
  .checkbox-wrapper {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
 .location-list-data{
  display:flex;
  justify-content:space-between;
 }
.toggle-columns-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
 
.toggle-columns-btn:hover {
 background-color: #0056b3;
}
 
 
  .select-button{
    width: 100%;
height: 54px;
border-radius: 10px;
background: transparent;
color: #333;
  font-size: 16px;
  border: 1px solid #d7d7d7;
  }
    .select-button {
      width: 100%;
      height: 54px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      padding: 0 10px;
      border-radius: 10px;
      background: transparent;
      color: #000;
      border: 1px solid #d7d7d7;
    }
  
    .web-setting-details .setting-details .basic-detail .profile-details .input-group .input-field {
      position: relative;
    }
  
    .carrier-search-main-data {
      width: 100%;
      position: absolute;
      max-height: 250px;
      overflow-y: auto;
      bottom: -255px;
      left: 0;
      background-color: #fff;
      padding: 10px;
      z-index: 11;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 20%);
    }
  
    .carrier-search-main-data ul {
      margin: 0;
    }
  
    .columns-dropdown {
      right: 15px;
      top: 46px;
    }
  
    .checkbox-wrapper label {
      display: flex;
      gap: 5px;
    }
  
    .add-employee-main .add-employee-details .employee-info-fill .input-group .input-field,
    .edit-employee-main .add-employee-details .employee-info-fill .input-group .input-field {
      position: relative;
    }
  
    .action-main ul li a img {
      width: 16px;
    }
.selected-file-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selected-file-container button{
background: transparent;
}
  .cross-image {
  position:relative;
    cursor: pointer;
    z-index: 1;
    }
.add-plan-main .add-plan-detail .input-group .input-field button {
  align-items: center;
  display: flex;
  background-color: transparent;
  justify-content: space-between;

}

.add-plan-main .add-plan-detail .input-group .input-field .type-button-one {
  align-items: center;
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  border: 1px solid #4B5563;
  height:50px;
}

.error-main p::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.error-main p::-webkit-scrollbar-track {
  background-color: gray;
  padding: 0 1px;
}
.error-main p::-webkit-scrollbar-thumb {
  background-color: #f9f9f9;
}
.error-main p {
  max-height: 65vh;
  overflow-y:auto ;
  text-align: left;
}
.error-main p ul, .error-main p ul li {
  list-style: disc;
  padding-left: 18px;
}

.carrier-search-main-data ul li {
  cursor: pointer;
  margin: 0 0 10px;
}

.carrier-search-main-data {
  height: 300px; /* Set a height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #f9f9f9 #f0f0f0; /* Thumb and track color for Firefox */
}

/* For Webkit Browsers (Chrome, Safari, Edge) */
.carrier-search-main-data::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.carrier-search-main-data::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
  border-radius: 10px; /* Rounded corners for the track */
}

.carrier-search-main-data::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #f9f9f9, #f9f9f9); /* Gradient thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); /* Subtle shadow inside the thumb */
}

.carrier-search-main-data::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #f9f9f9, #f9f9f9); /* Change gradient on hover */
}
.carrier-search-main-data .search-bar {
  position: relative;
  margin: 0 0 10px;
}

.carrier-search-main-data .search-bar input {
  height: 50px !important;
}

.carrier-search-main-data .search-bar button {
  width: auto !important;
  height: auto !important;
  border: none !important;
  position: absolute;
  right: 15px;
  top: 15px;
}
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: none;
}

.css-oeq6yz-MuiButtonBase-root-MuiIconButton-root {
  width: 45px !important;
}

.carrier-search-main .search-bar {
  padding: 0 !important;
}
.InovuaReactDataGrid__row--last .action-main-new {
  top: -38px !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content {
  text-align: center !important;
}
 
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-padding),
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__footer-row-cell,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header-group__title,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__locked-row-cell,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__nested-cell,
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__nested-group-cell {
  padding: 8px 4px !important;
}
 
.InovuaReactDataGrid, .InovuaReactDataGrid *, .InovuaReactDataGrid :after, .InovuaReactDataGrid :before {
  /* gap: 10px !important; */
}
.InovuaReactDataGrid__column-header--align-start {
  align-items: normal;
}
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content .InovuaReactDataGrid__sort-icon-wrapper, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__group-toolbar-item .InovuaReactDataGrid__sort-icon-wrapper {
  display: none  !important;
}
 
.custom-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
 
.file-input input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.file-input {
  position: relative;
  width: auto;
  padding: 0 15px;
  height: 35px;
  border-radius: 10px;
  border: 1px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.upload-sale-button{
  display: flex;
  gap: 10px;
  margin-left: auto;
}

button.upload-file_button {
  border-radius: 10px;
  padding: 5px 15px;
  border: 1px solid #FE0000;
    position: relative;
    border-radius: 10px !important;
    color: #FE0000;
    background: transparent;
}

.upload-sale-button div button{
  height: 35px !important;
}

.css-m0z6ex-MuiButtonBase-root-MuiButton-root {
  background: #ff3c3c !important;
}

button.action-btn {
  background: transparent;
}

.toggle-columns-btn {
  background-color: #fe0000;
  font-weight: 600;
}

.InovuaReactDataGrid__cell .InovuaReactDataGrid__cell__content,
.InovuaReactDataGrid__nested-cell .InovuaReactDataGrid__nested-group-cell__content,
.InovuaReactDataGrid__nested-group-cell .InovuaReactDataGrid__nested-group-cell__content {
  align-self: center !important;
  padding: 0 15px !important;
}
.toggle-columns-btn:hover {
  background-color: #fe0000 !important;
}